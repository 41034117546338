@import url(../../node_modules/balloon-css/balloon.css);
@-webkit-keyframes my-face {
  2% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  4% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  6% {
    -webkit-transform: translate(0, 1.5px) rotate(-1.5deg);
            transform: translate(0, 1.5px) rotate(-1.5deg); }
  8% {
    -webkit-transform: translate(0, -1.5px) rotate(-1.5deg);
            transform: translate(0, -1.5px) rotate(-1.5deg); }
  10% {
    -webkit-transform: translate(0, 2.5px) rotate(1.5deg);
            transform: translate(0, 2.5px) rotate(1.5deg); }
  12% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  14% {
    -webkit-transform: translate(0, -1.5px) rotate(1.5deg);
            transform: translate(0, -1.5px) rotate(1.5deg); }
  16% {
    -webkit-transform: translate(0, -0.5px) rotate(-1.5deg);
            transform: translate(0, -0.5px) rotate(-1.5deg); }
  18% {
    -webkit-transform: translate(0, 0.5px) rotate(-1.5deg);
            transform: translate(0, 0.5px) rotate(-1.5deg); }
  20% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  22% {
    -webkit-transform: translate(0, 0.5px) rotate(-1.5deg);
            transform: translate(0, 0.5px) rotate(-1.5deg); }
  24% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  26% {
    -webkit-transform: translate(0, 0.5px) rotate(0.5deg);
            transform: translate(0, 0.5px) rotate(0.5deg); }
  28% {
    -webkit-transform: translate(0, 0.5px) rotate(1.5deg);
            transform: translate(0, 0.5px) rotate(1.5deg); }
  30% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  32% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  34% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  36% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  38% {
    -webkit-transform: translate(0, 1.5px) rotate(-1.5deg);
            transform: translate(0, 1.5px) rotate(-1.5deg); }
  40% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  42% {
    -webkit-transform: translate(0, 2.5px) rotate(-1.5deg);
            transform: translate(0, 2.5px) rotate(-1.5deg); }
  44% {
    -webkit-transform: translate(0, 1.5px) rotate(0.5deg);
            transform: translate(0, 1.5px) rotate(0.5deg); }
  46% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  48% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  50% {
    -webkit-transform: translate(0, 0.5px) rotate(0.5deg);
            transform: translate(0, 0.5px) rotate(0.5deg); }
  52% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  54% {
    -webkit-transform: translate(0, -1.5px) rotate(1.5deg);
            transform: translate(0, -1.5px) rotate(1.5deg); }
  56% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  58% {
    -webkit-transform: translate(0, 0.5px) rotate(2.5deg);
            transform: translate(0, 0.5px) rotate(2.5deg); }
  60% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  62% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  64% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  66% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  68% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  70% {
    -webkit-transform: translate(0, 1.5px) rotate(0.5deg);
            transform: translate(0, 1.5px) rotate(0.5deg); }
  72% {
    -webkit-transform: translate(0, 2.5px) rotate(1.5deg);
            transform: translate(0, 2.5px) rotate(1.5deg); }
  74% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  76% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  78% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  80% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  82% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  84% {
    -webkit-transform: translate(0, 1.5px) rotate(2.5deg);
            transform: translate(0, 1.5px) rotate(2.5deg); }
  86% {
    -webkit-transform: translate(0, -1.5px) rotate(-1.5deg);
            transform: translate(0, -1.5px) rotate(-1.5deg); }
  88% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  90% {
    -webkit-transform: translate(0, 2.5px) rotate(-0.5deg);
            transform: translate(0, 2.5px) rotate(-0.5deg); }
  92% {
    -webkit-transform: translate(0, 0.5px) rotate(-0.5deg);
            transform: translate(0, 0.5px) rotate(-0.5deg); }
  94% {
    -webkit-transform: translate(0, 2.5px) rotate(0.5deg);
            transform: translate(0, 2.5px) rotate(0.5deg); }
  96% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  98% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); } }
@keyframes my-face {
  2% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  4% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  6% {
    -webkit-transform: translate(0, 1.5px) rotate(-1.5deg);
            transform: translate(0, 1.5px) rotate(-1.5deg); }
  8% {
    -webkit-transform: translate(0, -1.5px) rotate(-1.5deg);
            transform: translate(0, -1.5px) rotate(-1.5deg); }
  10% {
    -webkit-transform: translate(0, 2.5px) rotate(1.5deg);
            transform: translate(0, 2.5px) rotate(1.5deg); }
  12% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  14% {
    -webkit-transform: translate(0, -1.5px) rotate(1.5deg);
            transform: translate(0, -1.5px) rotate(1.5deg); }
  16% {
    -webkit-transform: translate(0, -0.5px) rotate(-1.5deg);
            transform: translate(0, -0.5px) rotate(-1.5deg); }
  18% {
    -webkit-transform: translate(0, 0.5px) rotate(-1.5deg);
            transform: translate(0, 0.5px) rotate(-1.5deg); }
  20% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  22% {
    -webkit-transform: translate(0, 0.5px) rotate(-1.5deg);
            transform: translate(0, 0.5px) rotate(-1.5deg); }
  24% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  26% {
    -webkit-transform: translate(0, 0.5px) rotate(0.5deg);
            transform: translate(0, 0.5px) rotate(0.5deg); }
  28% {
    -webkit-transform: translate(0, 0.5px) rotate(1.5deg);
            transform: translate(0, 0.5px) rotate(1.5deg); }
  30% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  32% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  34% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  36% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  38% {
    -webkit-transform: translate(0, 1.5px) rotate(-1.5deg);
            transform: translate(0, 1.5px) rotate(-1.5deg); }
  40% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  42% {
    -webkit-transform: translate(0, 2.5px) rotate(-1.5deg);
            transform: translate(0, 2.5px) rotate(-1.5deg); }
  44% {
    -webkit-transform: translate(0, 1.5px) rotate(0.5deg);
            transform: translate(0, 1.5px) rotate(0.5deg); }
  46% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
            transform: translate(0, -1.5px) rotate(2.5deg); }
  48% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  50% {
    -webkit-transform: translate(0, 0.5px) rotate(0.5deg);
            transform: translate(0, 0.5px) rotate(0.5deg); }
  52% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  54% {
    -webkit-transform: translate(0, -1.5px) rotate(1.5deg);
            transform: translate(0, -1.5px) rotate(1.5deg); }
  56% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  58% {
    -webkit-transform: translate(0, 0.5px) rotate(2.5deg);
            transform: translate(0, 0.5px) rotate(2.5deg); }
  60% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
            transform: translate(0, 2.5px) rotate(2.5deg); }
  62% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  64% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  66% {
    -webkit-transform: translate(0, 1.5px) rotate(-0.5deg);
            transform: translate(0, 1.5px) rotate(-0.5deg); }
  68% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  70% {
    -webkit-transform: translate(0, 1.5px) rotate(0.5deg);
            transform: translate(0, 1.5px) rotate(0.5deg); }
  72% {
    -webkit-transform: translate(0, 2.5px) rotate(1.5deg);
            transform: translate(0, 2.5px) rotate(1.5deg); }
  74% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  76% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  78% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  80% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
            transform: translate(0, 1.5px) rotate(1.5deg); }
  82% {
    -webkit-transform: translate(0, -0.5px) rotate(0.5deg);
            transform: translate(0, -0.5px) rotate(0.5deg); }
  84% {
    -webkit-transform: translate(0, 1.5px) rotate(2.5deg);
            transform: translate(0, 1.5px) rotate(2.5deg); }
  86% {
    -webkit-transform: translate(0, -1.5px) rotate(-1.5deg);
            transform: translate(0, -1.5px) rotate(-1.5deg); }
  88% {
    -webkit-transform: translate(0, -0.5px) rotate(2.5deg);
            transform: translate(0, -0.5px) rotate(2.5deg); }
  90% {
    -webkit-transform: translate(0, 2.5px) rotate(-0.5deg);
            transform: translate(0, 2.5px) rotate(-0.5deg); }
  92% {
    -webkit-transform: translate(0, 0.5px) rotate(-0.5deg);
            transform: translate(0, 0.5px) rotate(-0.5deg); }
  94% {
    -webkit-transform: translate(0, 2.5px) rotate(0.5deg);
            transform: translate(0, 2.5px) rotate(0.5deg); }
  96% {
    -webkit-transform: translate(0, -0.5px) rotate(1.5deg);
            transform: translate(0, -0.5px) rotate(1.5deg); }
  98% {
    -webkit-transform: translate(0, -1.5px) rotate(-0.5deg);
            transform: translate(0, -1.5px) rotate(-0.5deg); }
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); } }

.dplayer {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 1; }
  .dplayer * {
    box-sizing: content-box; }
  .dplayer svg {
    width: 100%;
    height: 100%; }
    .dplayer svg path,
    .dplayer svg circle {
      fill: #fff; }
  .dplayer:-webkit-full-screen {
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .dplayer:-webkit-full-screen .dplayer-danmaku .dplayer-danmaku-top.dplayer-danmaku-move,
    .dplayer:-webkit-full-screen .dplayer-danmaku .dplayer-danmaku-bottom.dplayer-danmaku-move {
      -webkit-animation: danmaku-center 6s linear;
              animation: danmaku-center 6s linear;
      -webkit-animation-play-state: inherit;
              animation-play-state: inherit; }
    .dplayer:-webkit-full-screen .dplayer-danmaku .dplayer-danmaku-right.dplayer-danmaku-move {
      -webkit-animation: danmaku 8s linear;
              animation: danmaku 8s linear;
      -webkit-animation-play-state: inherit;
              animation-play-state: inherit; }
  .dplayer.dplayer-no-danmaku .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box .dplayer-setting-showdan,
  .dplayer.dplayer-no-danmaku .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box .dplayer-setting-danmaku,
  .dplayer.dplayer-no-danmaku .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box .dplayer-setting-danunlimit {
    display: none; }
  .dplayer.dplayer-no-danmaku .dplayer-controller .dplayer-icons .dplayer-comment {
    display: none; }
  .dplayer.dplayer-no-danmaku .dplayer-danmaku {
    display: none; }
  .dplayer.dplayer-live .dplayer-time {
    display: none; }
  .dplayer.dplayer-live .dplayer-bar-wrap {
    display: none; }
  .dplayer.dplayer-live .dplayer-setting-speed {
    display: none; }
  .dplayer.dplayer-live .dplayer-setting-loop {
    display: none; }
  .dplayer.dplayer-live.dplayer-no-danmaku .dplayer-setting {
    display: none; }
  .dplayer.dplayer-arrow .dplayer-danmaku {
    font-size: 18px; }
  .dplayer.dplayer-arrow .dplayer-icon {
    margin: 0 -3px; }
  .dplayer.dplayer-playing .dplayer-danmaku .dplayer-danmaku-move {
    -webkit-animation-play-state: running;
            animation-play-state: running; }
  @media (min-width: 900px) {
    .dplayer.dplayer-playing .dplayer-controller-mask {
      opacity: 0; }
    .dplayer.dplayer-playing .dplayer-controller {
      opacity: 0; }
    .dplayer.dplayer-playing:hover .dplayer-controller-mask {
      opacity: 1; }
    .dplayer.dplayer-playing:hover .dplayer-controller {
      opacity: 1; } }
  .dplayer.dplayer-loading .dplayer-bezel .diplayer-loading-icon {
    display: block; }
  .dplayer.dplayer-loading .dplayer-danmaku,
  .dplayer.dplayer-loading .dplayer-danmaku-move, .dplayer.dplayer-paused .dplayer-danmaku,
  .dplayer.dplayer-paused .dplayer-danmaku-move {
    -webkit-animation-play-state: paused;
            animation-play-state: paused; }
  .dplayer.dplayer-hide-controller {
    cursor: none; }
    .dplayer.dplayer-hide-controller .dplayer-controller-mask {
      opacity: 0;
      -webkit-transform: translateY(100%);
              transform: translateY(100%); }
    .dplayer.dplayer-hide-controller .dplayer-controller {
      opacity: 0;
      -webkit-transform: translateY(100%);
              transform: translateY(100%); }
  .dplayer.dplayer-show-controller .dplayer-controller-mask {
    opacity: 1; }
  .dplayer.dplayer-show-controller .dplayer-controller {
    opacity: 1; }
  .dplayer.dplayer-fulled {
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .dplayer.dplayer-mobile .dplayer-controller .dplayer-icons .dplayer-volume,
  .dplayer.dplayer-mobile .dplayer-controller .dplayer-icons .dplayer-camera-icon {
    display: none; }
  .dplayer.dplayer-mobile .dplayer-controller .dplayer-icons .dplayer-full .dplayer-full-in-icon {
    position: static;
    display: inline-block; }
  .dplayer.dplayer-mobile .dplayer-bar-time {
    display: none; }

.dplayer-web-fullscreen-fix {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0; }

[data-balloon]:before {
  display: none; }

[data-balloon]:after {
  padding: 0.3em 0.7em;
  background: rgba(17, 17, 17, 0.7); }

[data-balloon][data-balloon-pos="up"]:after {
  margin-bottom: 0; }

.dplayer-bezel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 22px;
  color: #fff;
  pointer-events: none; }
  .dplayer-bezel .dplayer-bezel-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -26px 0 0 -26px;
    height: 52px;
    width: 52px;
    padding: 12px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    opacity: 0;
    pointer-events: none; }
    .dplayer-bezel .dplayer-bezel-icon.dplayer-bezel-transition {
      -webkit-animation: bezel-hide .5s linear;
              animation: bezel-hide .5s linear; }

@-webkit-keyframes bezel-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes bezel-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }
  .dplayer-bezel .dplayer-danloading {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    -webkit-animation: my-face 5s infinite ease-in-out;
            animation: my-face 5s infinite ease-in-out; }
  .dplayer-bezel .diplayer-loading-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
    height: 36px;
    width: 36px;
    pointer-events: none; }
    .dplayer-bezel .diplayer-loading-icon .diplayer-loading-hide {
      display: none; }
    .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot {
      -webkit-animation: diplayer-loading-dot-fade .8s ease infinite;
              animation: diplayer-loading-dot-fade .8s ease infinite;
      opacity: 0;
      -webkit-transform-origin: 4px 4px;
              transform-origin: 4px 4px; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-7 {
        -webkit-animation-delay: 0.7s;
                animation-delay: 0.7s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-6 {
        -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-5 {
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-4 {
        -webkit-animation-delay: 0.4s;
                animation-delay: 0.4s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-3 {
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-2 {
        -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s; }
      .dplayer-bezel .diplayer-loading-icon .diplayer-loading-dot.diplayer-loading-dot-1 {
        -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s; }

@-webkit-keyframes diplayer-loading-dot-fade {
  0% {
    opacity: .7;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  50% {
    opacity: .25;
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9); }
  to {
    opacity: .25;
    -webkit-transform: scale(0.85, 0.85);
            transform: scale(0.85, 0.85); } }

@keyframes diplayer-loading-dot-fade {
  0% {
    opacity: .7;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2); }
  50% {
    opacity: .25;
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9); }
  to {
    opacity: .25;
    -webkit-transform: scale(0.85, 0.85);
            transform: scale(0.85, 0.85); } }

.dplayer-controller-mask {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==) repeat-x bottom;
  height: 98px;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all 0.3s ease; }

.dplayer-controller {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 41px;
  padding: 0 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.3s ease; }
  .dplayer-controller.dplayer-controller-comment .dplayer-icons {
    display: none; }
  .dplayer-controller.dplayer-controller-comment .dplayer-icons.dplayer-comment-box {
    display: block; }
  .dplayer-controller .dplayer-bar-wrap {
    padding: 5px 0;
    cursor: pointer;
    position: absolute;
    bottom: 33px;
    width: calc(100% - 40px);
    height: 3px; }
    .dplayer-controller .dplayer-bar-wrap:hover .dplayer-bar .dplayer-played .dplayer-thumb {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .dplayer-controller .dplayer-bar-wrap:hover .dplayer-highlight {
      display: block;
      width: 8px;
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
      top: 4px;
      height: 40%; }
    .dplayer-controller .dplayer-bar-wrap .dplayer-highlight {
      z-index: 12;
      position: absolute;
      top: 5px;
      width: 6px;
      height: 20%;
      border-radius: 6px;
      background-color: #fff;
      text-align: center;
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
      transition: all .2s ease-in-out; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-highlight:hover .dplayer-highlight-text {
        display: block; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-highlight:hover ~ .dplayer-bar-preview {
        opacity: 0; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-highlight:hover ~ .dplayer-bar-time {
        opacity: 0; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-highlight .dplayer-highlight-text {
        display: none;
        position: absolute;
        left: 50%;
        top: -24px;
        padding: 5px 8px;
        background-color: rgba(0, 0, 0, 0.62);
        color: #fff;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
    .dplayer-controller .dplayer-bar-wrap .dplayer-bar-preview {
      position: absolute;
      background: #fff;
      pointer-events: none;
      display: none;
      background-size: 16000px 100%; }
    .dplayer-controller .dplayer-bar-wrap .dplayer-bar-preview-canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none; }
    .dplayer-controller .dplayer-bar-wrap .dplayer-bar-time {
      position: absolute;
      left: 0px;
      top: -20px;
      border-radius: 4px;
      padding: 5px 7px;
      background-color: rgba(0, 0, 0, 0.62);
      color: #fff;
      font-size: 12px;
      text-align: center;
      opacity: 1;
      transition: opacity .1s ease-in-out;
      word-wrap: normal;
      word-break: normal;
      z-index: 2;
      pointer-events: none; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-bar-time.hidden {
        opacity: 0; }
    .dplayer-controller .dplayer-bar-wrap .dplayer-bar {
      position: relative;
      height: 3px;
      width: 100%;
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-bar .dplayer-loaded {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.4);
        height: 3px;
        transition: all 0.5s ease;
        will-change: width; }
      .dplayer-controller .dplayer-bar-wrap .dplayer-bar .dplayer-played {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 3px;
        will-change: width; }
        .dplayer-controller .dplayer-bar-wrap .dplayer-bar .dplayer-played .dplayer-thumb {
          position: absolute;
          top: 0;
          right: 5px;
          margin-top: -4px;
          margin-right: -10px;
          height: 11px;
          width: 11px;
          border-radius: 50%;
          cursor: pointer;
          transition: all .3s ease-in-out;
          -webkit-transform: scale(0);
                  transform: scale(0); }
  .dplayer-controller .dplayer-icons {
    height: 38px;
    position: absolute;
    bottom: 0; }
    .dplayer-controller .dplayer-icons.dplayer-comment-box {
      display: none;
      position: absolute;
      transition: all .3s ease-in-out;
      z-index: 2;
      height: 38px;
      bottom: 0;
      left: 20px;
      right: 20px;
      color: #fff; }
      .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-icon {
        padding: 7px; }
      .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-icon {
        position: absolute;
        left: 0;
        top: 0; }
      .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-send-icon {
        position: absolute;
        right: 0;
        top: 0; }
      .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box {
        position: absolute;
        background: rgba(28, 28, 28, 0.9);
        bottom: 41px;
        left: 0;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        padding: 10px 10px 16px;
        font-size: 14px;
        width: 204px;
        transition: all .3s ease-in-out;
        -webkit-transform: scale(0);
                transform: scale(0); }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box.dplayer-comment-setting-open {
          -webkit-transform: scale(1);
                  transform: scale(1); }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box input[type=radio] {
          display: none; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box label {
          cursor: pointer; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-title {
          font-size: 13px;
          color: #fff;
          line-height: 30px; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type {
          font-size: 0; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type .dplayer-comment-setting-title {
            margin-bottom: 6px; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type label:nth-child(2) span {
            border-radius: 4px 0 0 4px; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type label:nth-child(4) span {
            border-radius: 0 4px 4px 0; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type span {
            width: 33%;
            padding: 4px 6px;
            line-height: 16px;
            display: inline-block;
            font-size: 12px;
            color: #fff;
            border: 1px solid #fff;
            margin-right: -1px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-type input:checked + span {
            background: #E4E4E6;
            color: #1c1c1c; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-color {
          font-size: 0; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-color label {
            font-size: 0;
            padding: 6px;
            display: inline-block; }
          .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-color span {
            width: 22px;
            height: 22px;
            display: inline-block;
            border-radius: 50%;
            box-sizing: border-box;
            cursor: pointer; }
            .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-setting-box .dplayer-comment-setting-color span:hover {
              -webkit-animation: my-face 5s infinite ease-in-out;
                      animation: my-face 5s infinite ease-in-out; }
      .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input {
        outline: none;
        border: none;
        padding: 8px 31px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        border-radius: 4px;
        background: none;
        margin: 0;
        height: 100%;
        box-sizing: border-box;
        width: 100%;
        color: #fff; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input::-webkit-input-placeholder {
          color: #fff;
          opacity: 0.8; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input:-ms-input-placeholder {
          color: #fff;
          opacity: 0.8; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input::-ms-input-placeholder {
          color: #fff;
          opacity: 0.8; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input::placeholder {
          color: #fff;
          opacity: 0.8; }
        .dplayer-controller .dplayer-icons.dplayer-comment-box .dplayer-comment-input::-ms-clear {
          display: none; }
    .dplayer-controller .dplayer-icons.dplayer-icons-left .dplayer-icon {
      padding: 7px; }
    .dplayer-controller .dplayer-icons.dplayer-icons-right {
      right: 20px; }
      .dplayer-controller .dplayer-icons.dplayer-icons-right .dplayer-icon {
        padding: 8px; }
    .dplayer-controller .dplayer-icons .dplayer-time,
    .dplayer-controller .dplayer-icons .dplayer-live-badge {
      line-height: 38px;
      color: #eee;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
      vertical-align: middle;
      font-size: 13px;
      cursor: default; }
    .dplayer-controller .dplayer-icons .dplayer-live-dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      vertical-align: 4%;
      margin-right: 5px;
      content: '';
      border-radius: 6px; }
    .dplayer-controller .dplayer-icons .dplayer-icon {
      width: 40px;
      height: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      vertical-align: middle;
      box-sizing: border-box;
      display: inline-block; }
      .dplayer-controller .dplayer-icons .dplayer-icon .dplayer-icon-content {
        transition: all .2s ease-in-out;
        opacity: .8; }
      .dplayer-controller .dplayer-icons .dplayer-icon:hover .dplayer-icon-content {
        opacity: 1; }
      .dplayer-controller .dplayer-icons .dplayer-icon.dplayer-quality-icon {
        color: #fff;
        width: auto;
        line-height: 22px;
        font-size: 14px; }
      .dplayer-controller .dplayer-icons .dplayer-icon.dplayer-comment-icon {
        padding: 10px 9px 9px; }
      .dplayer-controller .dplayer-icons .dplayer-icon.dplayer-setting-icon {
        padding-top: 8.5px; }
      .dplayer-controller .dplayer-icons .dplayer-icon.dplayer-volume-icon {
        width: 43px; }
    .dplayer-controller .dplayer-icons .dplayer-volume {
      position: relative;
      display: inline-block;
      cursor: pointer;
      height: 100%; }
      .dplayer-controller .dplayer-icons .dplayer-volume:hover .dplayer-volume-bar-wrap .dplayer-volume-bar {
        width: 45px; }
      .dplayer-controller .dplayer-icons .dplayer-volume:hover .dplayer-volume-bar-wrap .dplayer-volume-bar .dplayer-volume-bar-inner .dplayer-thumb {
        -webkit-transform: scale(1);
                transform: scale(1); }
      .dplayer-controller .dplayer-icons .dplayer-volume.dplayer-volume-active .dplayer-volume-bar-wrap .dplayer-volume-bar {
        width: 45px; }
      .dplayer-controller .dplayer-icons .dplayer-volume.dplayer-volume-active .dplayer-volume-bar-wrap .dplayer-volume-bar .dplayer-volume-bar-inner .dplayer-thumb {
        -webkit-transform: scale(1);
                transform: scale(1); }
      .dplayer-controller .dplayer-icons .dplayer-volume .dplayer-volume-bar-wrap {
        display: inline-block;
        margin: 0 10px 0 -5px;
        vertical-align: middle;
        height: 100%; }
        .dplayer-controller .dplayer-icons .dplayer-volume .dplayer-volume-bar-wrap .dplayer-volume-bar {
          position: relative;
          top: 17px;
          width: 0;
          height: 3px;
          background: #aaa;
          transition: all 0.3s ease-in-out; }
          .dplayer-controller .dplayer-icons .dplayer-volume .dplayer-volume-bar-wrap .dplayer-volume-bar .dplayer-volume-bar-inner {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            transition: all 0.1s ease;
            will-change: width; }
            .dplayer-controller .dplayer-icons .dplayer-volume .dplayer-volume-bar-wrap .dplayer-volume-bar .dplayer-volume-bar-inner .dplayer-thumb {
              position: absolute;
              top: 0;
              right: 5px;
              margin-top: -4px;
              margin-right: -10px;
              height: 11px;
              width: 11px;
              border-radius: 50%;
              cursor: pointer;
              transition: all .3s ease-in-out;
              -webkit-transform: scale(0);
                      transform: scale(0); }
    .dplayer-controller .dplayer-icons .dplayer-subtitle-btn {
      display: inline-block;
      height: 100%; }
    .dplayer-controller .dplayer-icons .dplayer-setting {
      display: inline-block;
      height: 100%; }
      .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box {
        position: absolute;
        right: 0;
        bottom: 50px;
        -webkit-transform: scale(0);
                transform: scale(0);
        width: 150px;
        border-radius: 2px;
        background: rgba(28, 28, 28, 0.9);
        padding: 7px 0;
        transition: all .3s ease-in-out;
        overflow: hidden;
        z-index: 2; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box > div {
          display: none; }
          .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box > div.dplayer-setting-origin-panel {
            display: block; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box.dplayer-setting-box-open {
          -webkit-transform: scale(1);
                  transform: scale(1); }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box.dplayer-setting-box-narrow {
          width: 70px;
          height: 180px;
          text-align: center; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box.dplayer-setting-box-speed .dplayer-setting-origin-panel {
          display: none; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-box.dplayer-setting-box-speed .dplayer-setting-speed-panel {
          display: block; }
      .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-item,
      .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-speed-item {
        height: 30px;
        padding: 5px 10px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-item:hover,
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-speed-item:hover {
          background-color: rgba(255, 255, 255, 0.1); }
      .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku {
        padding: 5px 0; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku .dplayer-label {
          padding: 0 10px;
          display: inline; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku:hover .dplayer-label {
          display: none; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku:hover .dplayer-danmaku-bar-wrap {
          display: inline-block; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku.dplayer-setting-danmaku-active .dplayer-label {
          display: none; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku.dplayer-setting-danmaku-active .dplayer-danmaku-bar-wrap {
          display: inline-block; }
        .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku .dplayer-danmaku-bar-wrap {
          padding: 0 10px;
          box-sizing: border-box;
          display: none;
          vertical-align: middle;
          height: 100%;
          width: 100%; }
          .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku .dplayer-danmaku-bar-wrap .dplayer-danmaku-bar {
            position: relative;
            top: 8.5px;
            width: 100%;
            height: 3px;
            background: #fff;
            transition: all 0.3s ease-in-out; }
            .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku .dplayer-danmaku-bar-wrap .dplayer-danmaku-bar .dplayer-danmaku-bar-inner {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 100%;
              transition: all 0.1s ease;
              background: #aaa;
              will-change: width; }
              .dplayer-controller .dplayer-icons .dplayer-setting .dplayer-setting-danmaku .dplayer-danmaku-bar-wrap .dplayer-danmaku-bar .dplayer-danmaku-bar-inner .dplayer-thumb {
                position: absolute;
                top: 0;
                right: 5px;
                margin-top: -4px;
                margin-right: -10px;
                height: 11px;
                width: 11px;
                border-radius: 50%;
                cursor: pointer;
                transition: all .3s ease-in-out;
                background: #aaa; }
    .dplayer-controller .dplayer-icons .dplayer-full {
      display: inline-block;
      height: 100%;
      position: relative; }
      .dplayer-controller .dplayer-icons .dplayer-full:hover .dplayer-full-in-icon {
        display: block; }
      .dplayer-controller .dplayer-icons .dplayer-full .dplayer-full-in-icon {
        position: absolute;
        top: -30px;
        z-index: 1;
        display: none; }
    .dplayer-controller .dplayer-icons .dplayer-quality {
      position: relative;
      display: inline-block;
      height: 100%;
      z-index: 2; }
      .dplayer-controller .dplayer-icons .dplayer-quality:hover .dplayer-quality-list {
        display: block; }
      .dplayer-controller .dplayer-icons .dplayer-quality:hover .dplayer-quality-mask {
        display: block; }
      .dplayer-controller .dplayer-icons .dplayer-quality .dplayer-quality-mask {
        display: none;
        position: absolute;
        bottom: 38px;
        left: -18px;
        width: 80px;
        padding-bottom: 12px; }
      .dplayer-controller .dplayer-icons .dplayer-quality .dplayer-quality-list {
        display: none;
        font-size: 12px;
        width: 80px;
        border-radius: 2px;
        background: rgba(28, 28, 28, 0.9);
        padding: 5px 0;
        transition: all .3s ease-in-out;
        overflow: hidden;
        color: #fff;
        text-align: center; }
      .dplayer-controller .dplayer-icons .dplayer-quality .dplayer-quality-item {
        height: 25px;
        box-sizing: border-box;
        cursor: pointer;
        line-height: 25px; }
        .dplayer-controller .dplayer-icons .dplayer-quality .dplayer-quality-item:hover {
          background-color: rgba(255, 255, 255, 0.1); }
    .dplayer-controller .dplayer-icons .dplayer-comment {
      display: inline-block;
      height: 100%; }
    .dplayer-controller .dplayer-icons .dplayer-label {
      color: #eee;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap; }
    .dplayer-controller .dplayer-icons .dplayer-toggle {
      width: 32px;
      height: 20px;
      text-align: center;
      font-size: 0;
      vertical-align: middle;
      position: absolute;
      top: 5px;
      right: 10px; }
      .dplayer-controller .dplayer-icons .dplayer-toggle input {
        max-height: 0;
        max-width: 0;
        display: none; }
      .dplayer-controller .dplayer-icons .dplayer-toggle input + label {
        display: inline-block;
        position: relative;
        box-shadow: #dfdfdf 0 0 0 0 inset;
        border: 1px solid #dfdfdf;
        height: 20px;
        width: 32px;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;
        transition: .2s ease-in-out; }
      .dplayer-controller .dplayer-icons .dplayer-toggle input + label:before {
        content: "";
        position: absolute;
        display: block;
        height: 18px;
        width: 18px;
        top: 0;
        left: 0;
        border-radius: 15px;
        transition: .2s ease-in-out; }
      .dplayer-controller .dplayer-icons .dplayer-toggle input + label:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        border-radius: 15px;
        background: #fff;
        transition: .2s ease-in-out;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        height: 18px;
        width: 18px; }
      .dplayer-controller .dplayer-icons .dplayer-toggle input:checked + label {
        border-color: rgba(255, 255, 255, 0.5); }
      .dplayer-controller .dplayer-icons .dplayer-toggle input:checked + label:before {
        width: 30px;
        background: rgba(255, 255, 255, 0.5); }
      .dplayer-controller .dplayer-icons .dplayer-toggle input:checked + label:after {
        left: 12px; }

.dplayer-danmaku {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 22px;
  color: #fff; }
  .dplayer-danmaku .dplayer-danmaku-item {
    display: inline-block;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default;
    white-space: nowrap;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5); }
    .dplayer-danmaku .dplayer-danmaku-item--demo {
      position: absolute;
      visibility: hidden; }
  .dplayer-danmaku .dplayer-danmaku-right {
    position: absolute;
    right: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
    .dplayer-danmaku .dplayer-danmaku-right.dplayer-danmaku-move {
      will-change: transform;
      -webkit-animation: danmaku 5s linear;
              animation: danmaku 5s linear;
      -webkit-animation-play-state: paused;
              animation-play-state: paused; }

@-webkit-keyframes danmaku {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes danmaku {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }
  .dplayer-danmaku .dplayer-danmaku-top,
  .dplayer-danmaku .dplayer-danmaku-bottom {
    position: absolute;
    width: 100%;
    text-align: center;
    visibility: hidden; }
    .dplayer-danmaku .dplayer-danmaku-top.dplayer-danmaku-move,
    .dplayer-danmaku .dplayer-danmaku-bottom.dplayer-danmaku-move {
      will-change: visibility;
      -webkit-animation: danmaku-center 4s linear;
              animation: danmaku-center 4s linear;
      -webkit-animation-play-state: paused;
              animation-play-state: paused; }

@-webkit-keyframes danmaku-center {
  from {
    visibility: visible; }
  to {
    visibility: visible; } }

@keyframes danmaku-center {
  from {
    visibility: visible; }
  to {
    visibility: visible; } }

.dplayer-logo {
  pointer-events: none;
  position: absolute;
  left: 20px;
  top: 20px;
  max-width: 50px;
  max-height: 50px; }
  .dplayer-logo img {
    max-width: 100%;
    max-height: 100%;
    background: none; }

.dplayer-menu {
  position: absolute;
  width: 170px;
  border-radius: 2px;
  background: rgba(28, 28, 28, 0.85);
  padding: 5px 0;
  overflow: hidden;
  z-index: 3;
  display: none; }
  .dplayer-menu.dplayer-menu-show {
    display: block; }
  .dplayer-menu .dplayer-menu-item {
    height: 30px;
    box-sizing: border-box;
    cursor: pointer; }
    .dplayer-menu .dplayer-menu-item:hover {
      background-color: rgba(255, 255, 255, 0.1); }
    .dplayer-menu .dplayer-menu-item a {
      display: inline-block;
      padding: 0 10px;
      line-height: 30px;
      color: #eee;
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .dplayer-menu .dplayer-menu-item a:hover {
        text-decoration: none; }

.dplayer-notice {
  opacity: 0;
  position: absolute;
  bottom: 60px;
  left: 20px;
  font-size: 14px;
  border-radius: 2px;
  background: rgba(28, 28, 28, 0.9);
  padding: 7px 20px;
  transition: all .3s ease-in-out;
  overflow: hidden;
  color: #fff;
  pointer-events: none; }

.dplayer-subtitle {
  position: absolute;
  bottom: 40px;
  width: 90%;
  left: 5%;
  text-align: center;
  color: #fff;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
  font-size: 20px; }
  .dplayer-subtitle.dplayer-subtitle-hide {
    display: none; }

.dplayer-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: none; }
  .dplayer-mask.dplayer-mask-show {
    display: block; }

.dplayer-video-wrap {
  position: relative;
  background: #000;
  font-size: 0;
  width: 100%;
  height: 100%; }
  .dplayer-video-wrap .dplayer-video {
    width: 100%;
    height: 100%;
    display: none; }
  .dplayer-video-wrap .dplayer-video-current {
    display: block; }
  .dplayer-video-wrap .dplayer-video-prepare {
    display: none; }

.dplayer-info-panel {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 400px;
  background: rgba(28, 28, 28, 0.8);
  padding: 10px;
  color: #fff;
  font-size: 12px;
  border-radius: 2px; }
  .dplayer-info-panel-hide {
    display: none; }
  .dplayer-info-panel .dplayer-info-panel-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px; }
  .dplayer-info-panel .dplayer-info-panel-item > span {
    display: inline-block;
    vertical-align: middle;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dplayer-info-panel .dplayer-info-panel-item-title {
    width: 100px;
    text-align: right;
    margin-right: 10px; }
  .dplayer-info-panel .dplayer-info-panel-item-data {
    width: 260px; }
